var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tSectionOffice",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tSectionOfficeForm",
          attrs: {
            model: _vm.tSectionOfficeForm,
            rules: _vm.tSectionOfficeFormRule,
            "label-width": 120,
          },
        },
        [
          _c(
            "FormItem",
            { attrs: { label: "科室编码", prop: "sectionCode" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入科室编码",
                },
                model: {
                  value: _vm.tSectionOfficeForm.sectionCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.tSectionOfficeForm, "sectionCode", $$v)
                  },
                  expression: "tSectionOfficeForm.sectionCode",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "科室名称", prop: "sectionName" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入科室名称",
                },
                model: {
                  value: _vm.tSectionOfficeForm.sectionName,
                  callback: function ($$v) {
                    _vm.$set(_vm.tSectionOfficeForm, "sectionName", $$v)
                  },
                  expression: "tSectionOfficeForm.sectionName",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "科室简拼", prop: "sectionAlphbet" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入科室简拼",
                },
                model: {
                  value: _vm.tSectionOfficeForm.sectionAlphbet,
                  callback: function ($$v) {
                    _vm.$set(_vm.tSectionOfficeForm, "sectionAlphbet", $$v)
                  },
                  expression: "tSectionOfficeForm.sectionAlphbet",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "排序", prop: "orderNum" } },
            [
              _c("InputNumber", {
                attrs: {
                  type: "number",
                  disabled: _vm.disabled,
                  min: 1,
                  step: 0.1,
                  placeholder: "请输入排序",
                },
                model: {
                  value: _vm.tSectionOfficeForm.orderNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.tSectionOfficeForm, "orderNum", $$v)
                  },
                  expression: "tSectionOfficeForm.orderNum",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "联系人" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入联系人",
                },
                model: {
                  value: _vm.tSectionOfficeForm.contact,
                  callback: function ($$v) {
                    _vm.$set(_vm.tSectionOfficeForm, "contact", $$v)
                  },
                  expression: "tSectionOfficeForm.contact",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "联系人电话" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入联系人电话",
                },
                model: {
                  value: _vm.tSectionOfficeForm.contactMobile,
                  callback: function ($$v) {
                    _vm.$set(_vm.tSectionOfficeForm, "contactMobile", $$v)
                  },
                  expression: "tSectionOfficeForm.contactMobile",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "检查类型" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                    multiple: "",
                  },
                  model: {
                    value: _vm.tSectionOfficeForm.checkTypeList,
                    callback: function ($$v) {
                      _vm.$set(_vm.tSectionOfficeForm, "checkTypeList", $$v)
                    },
                    expression: "tSectionOfficeForm.checkTypeList",
                  },
                },
                _vm._l(_vm.checkTypePriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "科室医学类型" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "科室医学类型",
                    clearable: "",
                  },
                  model: {
                    value: _vm.tSectionOfficeForm.sectionType,
                    callback: function ($$v) {
                      _vm.$set(_vm.tSectionOfficeForm, "sectionType", $$v)
                    },
                    expression: "tSectionOfficeForm.sectionType",
                  },
                },
                _vm._l(_vm.sectionTypePriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.id, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "Button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handelSubmit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }